import AzureFunctionClient from "./AzureFunctionClient";
import BaseService from "./BaseService";
import dayjs from "dayjs";

export default class ConsumptionService extends BaseService {
    constructor() {
        super();
        this.baseUrl = "/delegate/yithome-api/obixconsumption";
        this.hcID = null;
    }
    
    /**
     * Get consumption data for project and apartment
     * @param {*} params 
     */
    async getData(params = {}) {

        if (!params.provider)
            throw "You must provide field provider";
        if (!params.projectId)
            throw "You must provide field projectId";

        let payload = {
            provider: params.provider,
            id: params.projectId,
            datatype: 'data',
            mode: params.mode ? params.mode : 'day',
            showmetered: params.showmetered === true || params.showmetered == 'true' ? true : false
        };

        if (params.consumptionType)
            payload.consumptionType = params.consumptionType;
      
        if (params.apartmentId)
            payload.apartmentId = params.apartmentId;
        if (params.startdate)
            payload.startdate = dayjs(params.startdate).format('YYYY-MM-DD 00:00:00');
         if (params.enddate)
            payload.enddate = dayjs(params.enddate).format('YYYY-MM-DD 23:59:59');

        //payload.id = '1-IEIOF1';
        //payload.apartmentId = '1-IEJ7V3';
        
        let query = this._buildUrl(`/consumptionData`, payload);
        return AzureFunctionClient.get(query, {
            timeout: 90000
        });
    }

    /**
     * Get consumption data for project and apartment
     * @param {*} params 
     */
    async getDevices(params = {}) {

      if (!params.provider)
        params.provider = 'Caverion';
        if (!params.projectId)
            throw "You must provide field projectId";
        /*if (!params.apartmentId)
            throw "You must provide field apartmentId";*/

        let payload = {
            provider: params.provider,
            id: params.projectId,
            datatype: 'devices'
        };

        if (params.apartmentId)
          payload.apartmentId = params.apartmentId;

        let query = this._buildUrl(`/consumptionData`, payload);
        return AzureFunctionClient.get(query);
    }

    /**
     * Get project consumption settings
     * @param {*} externalId 
     * @param {*} provider 
     * @returns 
     */
    async getConsumptionSettingsForProject(externalId, provider = "Caverion") {
        let payload = {
            externalId
            //provider
        }
        return AzureFunctionClient.get(this._buildUrl(`/consumptionProvider`, payload));
    }

    /**
     * Run consumption poller manually
     * @param {*} id 
     * @returns 
     */
    async runConsumptionPoller(id, provider = "Caverion") {
        let payload = {
            id,
            provider
        }
        return AzureFunctionClient.get(this._buildUrl(`/consumptionPoller`, payload), {timeout: 900000});
    }

    /**
     * Run consumption reporort
     * @param {Object} args
     * @returns 
     */
    async consumptionReport(args = {}) {

        let reportBuffer = await AzureFunctionClient.get(this._buildUrl(`/consumptionReport`, args), { timeout: 900000, responseType: 'blob' });
        
        let fileName = args.fileName ? args.fileName : `report.csv`;
        let fileURL = window.URL.createObjectURL(new Blob([reportBuffer.data]));
        let fileLink = document.createElement('a');
        
        fileLink.href = fileURL;
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
        return; 
    }
}