import ConsumptionService from "@/services/ConsumptionService";
import LiferayConsumptionService from "@/services/LiferayConsumptionService";
import dayjs from "dayjs";

const api = new ConsumptionService();
const liferayApi = new LiferayConsumptionService();

const getDefaultState = () => {
  return {
    consumptionData: null,
    consumptionMeters: [],
    selectedAdminProjectId: null,
  };
};

const state = getDefaultState();

const getters = {
  getConsumptionData: (state) => state.consumptionData,
  getConsumptionMeters: (state) => state.consumptionMeters,
  isVisibleConsumptionType: (state) => (type) => {
    type = String(type).toLowerCase();
    let meters = state.consumptionMeters.find((meter) => {
      //console.log("consumption type: ", retrieveTypeName(meter.consumption_data_type), meter.consumption_data_type, type);
      if (
        meter.consumption_group_name === type ||
        retrieveTypeName(meter.consumption_data_type) === type
      )
        return true;

      return false;
    });

    if (meters) return true;

    return false;
  },
  getSelectedAdminProjectId: (state) => state.selectedAdminProjectId,
};

//import consumptionData from '../../utils/mockDataManager.json';
const actions = {
  async fetchConsumptionData({ commit }, params) {
    try {
      let startdate = dayjs().startOf("year");
      let enddate = dayjs().endOf("year").add(1, "day");

      let response = null;

      //Dirty hack to make 2 xhr requests to get current month included
      //console.log(params, params.mode, dayjs(params.startdate).format('YYYY-MM-DD'), startdate.format('YYYY-MM-DD'), dayjs(params.enddate).format('YYYY-MM-DD'), enddate.format('YYYY-MM-DD'));
      if (
        params.mode == "month" &&
        dayjs(params.startdate).format("YYYY-MM-DD") ==
          startdate.format("YYYY-MM-DD") &&
        dayjs(params.enddate).format("YYYY-MM-DD") ==
          enddate.format("YYYY-MM-DD")
      ) {
        let promises = [];
        promises.push(api.getData(params));
        promises.push(
          api.getData(
            Object.assign(
              { ...params },
              {
                mode: "day",
                startdate: dayjs().startOf("month"),
                enddate: dayjs().endOf("month"),
              }
            )
          )
        );

        let results = await Promise.all(promises);
        response = results[0];
        if (params.apartmentId) {
          if (
            response.data &&
            response.data.consumption &&
            response.data.consumption.length
          ) {
            if (
              results[1] &&
              results[1].data &&
              results[1].data.consumption &&
              results[1].data.consumption.length
            ) {
              response.data.consumption = response.data.consumption.map(
                (device) => {
                  if (device) {
                    results[1].data.consumption.map((deviceCurrentMonth) => {
                      if (deviceCurrentMonth) {
                        if (device.name === deviceCurrentMonth.name) {
                          device.values.push({
                            date: dayjs().format("YYYY-MM"),
                            label: dayjs().month() + 1,
                            value: deviceCurrentMonth.totalValue
                              ? deviceCurrentMonth.totalValue.value
                              : 0,
                          });
                          if (device.totalValue)
                            device.totalValue.value =
                              deviceCurrentMonth.totalValue
                                ? deviceCurrentMonth.totalValue.value
                                : 0;
                        }
                      }
                    });
                  }
                  return device;
                }
              );
            }
          }

          //console.log("MULTI XHR RESPONSE DATA: ", response);
        } else {
          if (Array.isArray(response.data) && Array.isArray(results[1].data)) {
            response.data = response.data.map((apt) => {
              let aptFound = false;
              results[1].data.map((aptCurrentMonth) => {
                if (apt.apartmentId === aptCurrentMonth.apartmentId) {
                  aptFound = true;
                  apt.consumption = apt.consumption.map((device) => {
                    if (device) {
                      aptCurrentMonth.consumption.map((deviceCurrentMonth) => {
                        if (deviceCurrentMonth) {
                          if (device.name === deviceCurrentMonth.name) {
                            device.values.push({
                              date: dayjs().format("YYYY-MM"),
                              label: dayjs().month() + 1,
                              value: device.totalValue
                                ? device.totalValue.value
                                : 0,
                            });
                            if (device.totalValue)
                              device.totalValue.value = device.totalValue
                                ? device.totalValue.value
                                : 0;
                          }
                        }
                      });
                    }
                    return device;
                  });
                }

                return aptCurrentMonth;
              });
              /*if (!aptFound)
                response.data.push(aptCurrentMonth);*/
              return apt;
            });
          }

          //console.log("MULTI XHR RESPONSE DATA: ", response);
        }
      } else {
        response = await api.getData(params);
      }

      //console.log("Fetched Consumption Data: ", response);
      if (response && response.data) {
        commit("setConsumptionData", response.data);
        return response.data;
      } else {
        commit("setConsumptionData", null);
        return null;
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async fetchConsumptionMeters({ commit }, params) {
    try {
      let response = await api.getDevices(params);
      //console.log("Fetched Consumption Devices: ", response.data);
      if (response && response.data) {
        commit("setConsumptionMeters", response.data);
        return response.data;
      } else {
        commit("setConsumptionMeters", []);
        return null;
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};

const mutations = {
  setConsumptionData: (state, data) => {
    state.consumptionData = data;
  },
  resetConsumptionData: (state) => {
    state.consumptionData = null;
    state.consumptionMeters = null;
  },
  setConsumptionMeters: (state, data) => {
    state.consumptionMeters = data;
  },
  setSelectedAdminProjectId: (state, data) => {
    state.selectedAdminProjectId = data;
  },
};

//VERY TEMPORARY
const consumptionDateTypeMap = {
  1: "temperature",
  4: "water",
  5: "water",
  6: "water",
  7: "temperature",
  8: "electricity",
};

const retrieveTypeName = (type_id) => {
  if (consumptionDateTypeMap.hasOwnProperty(type_id))
    return String(consumptionDateTypeMap[type_id]).toLowerCase();

  return false;
};

export default {
  getDefaultState,
  state,
  getters,
  actions,
  mutations,
};
